import React, { useCallback, useState } from "react";
import ChampionCard from "../ChampionCard/ChampionCard";
import { Grid } from "@mui/material";
import { useTransition, animated, config } from "react-spring";
import InfoModal from "../Modals/InfoModal.jsx";
import { useChampionData } from "../../contexts/ChampionDataContext";
import { useChampionSearch } from "../../contexts/ChampionSearchContext";
import LikesModal from "../Modals/LikesModal";

function ChampionGrid() {
  const { championTotalLikes } = useChampionData();
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isLikesModalOpen, setIsLikesModalOpen] = useState(false);
  const [selectedChampion, setSelectedChampion] = useState(null);

  const { filteredChampions } = useChampionSearch();

  const handleModalOpen = useCallback((setModalOpenFunction, champion) => {
    setSelectedChampion(champion);
    setModalOpenFunction(true);
  }, []);

  const handleModalClose = useCallback((setModalOpenFunction) => {
    setModalOpenFunction(false);
  }, []);

  const transitions = useTransition(filteredChampions, {
    keys: (champion) => champion.name,
    from: { opacity: 0, transform: "scale(0.95)" },
    enter: { opacity: 1, transform: "scale(1.05)" },
    leave: { opacity: 0, transform: "scale(0.95)" },
    config: { ...config.gentle, duration: 300 },
  });

  return (
    <Grid mt={5} container spacing={2} sx={{ justifyContent: "center" }}>
      {transitions((styles, champion) => (
        <Grid item key={champion.name}>
          <animated.div style={styles}>
            <ChampionCard
              champion={champion}
              onCardClick={() => {
                if (champion.config || champion.warning) {
                  handleModalOpen(setIsInfoModalOpen, champion);
                }
              }}
              onClickLike={() => handleModalOpen(setIsLikesModalOpen, champion)}
              likes={championTotalLikes[champion.name] || "-"}
            />
          </animated.div>
        </Grid>
      ))}
      <InfoModal
        open={isInfoModalOpen}
        onClose={() => handleModalClose(setIsInfoModalOpen)}
        champion={selectedChampion}
      />

      <LikesModal
        open={isLikesModalOpen}
        onClose={() => handleModalClose(setIsLikesModalOpen)}
        champion={selectedChampion}
      />
    </Grid>
  );
}

export default ChampionGrid;
