import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { useError } from "./ErrorContext";
import { useQuery } from "react-query";
import { handleQueryError } from "../utils/queryUtils";

export const ChampionDataContext = createContext(null);

export const CHAMPIONS_DATA_QK = "championsData";

export const ChampionDataProvider = ({ children }) => {
  const {
    actions: { setError },
  } = useError();

  const [championTeamLikes, setChampionTeamLikes] = useState({});
  const [championTotalLikes, setChampionTotalLikes] = useState({});

  useQuery(
    CHAMPIONS_DATA_QK,
    async () => {
      const response = await axios.get(
        "/.netlify/functions/championFunctions/getChampionsData",
      );
      return response.data;
    },
    {
      onError: handleQueryError(setError, "fetching all champion likes"),
      refetchOnWindowFocus: false,
      /*  enabled: !!userVotes,*/
      onSuccess: (championData) => {
        const teamLikes = championData.reduce((acc, championLike) => {
          const { teams, name } = championLike;
          acc[name] = Object.fromEntries(
            Object.entries(teams).filter(([teamName]) => teamName !== "rs"),
          );
          return acc;
        }, {});

        setChampionTeamLikes(teamLikes);

        const totalLikes = championData.reduce((acc, championLike) => {
          const { teams, name } = championLike;
          const filteredTeams = Object.fromEntries(
            Object.entries(teams).filter(([teamName]) => teamName !== "rs"),
          );
          acc[name] = Object.values(filteredTeams).reduce((a, b) => a + b, 0);
          return acc;
        }, {});

        setChampionTotalLikes(totalLikes);
      },
    },
  );

  return (
    <ChampionDataContext.Provider
      value={{ championTeamLikes, championTotalLikes }}
    >
      {children}
    </ChampionDataContext.Provider>
  );
};

export const useChampionData = () => useContext(ChampionDataContext);
