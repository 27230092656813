import { Box as MuiBox, styled } from "@mui/material";

const Box = styled(MuiBox)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 10px;
`;
const TeamChips = ({ champion }) => {
  return (
    <Box>
      {["ls", "fh", "rs"].map((prop, index) =>
        champion[prop] ? (
          <span
            key={`${champion.name}-${index}`}
            style={{
              fontSize: "0.6rem",
              backgroundColor: "#252525",
              borderRadius: "20%",
              padding: "6px",
              fontFamily: "inter, sans-serif",
              color: "#868686",
            }}
          >
            {prop.toUpperCase()}
          </span>
        ) : null,
      )}
    </Box>
  );
};
export default TeamChips;
