import React from "react";
import { StyledIconButton } from "../Search.styles";
import {
  StyledArrowDownward,
  StyledArrowUpward,
} from "./SortOrderButtons.styles";

const SortOrderButtons = ({ sortOrder, handleScoreSort }) => {
  return (
    <>
      <StyledIconButton onClick={() => handleScoreSort("asc")}>
        <StyledArrowUpward
          $active={sortOrder === "asc"}
          $noneSelected={!sortOrder}
        />
      </StyledIconButton>
      <StyledIconButton onClick={() => handleScoreSort("desc")}>
        <StyledArrowDownward
          $active={sortOrder === "desc"}
          $noneSelected={!sortOrder}
        />
      </StyledIconButton>
    </>
  );
};

export default SortOrderButtons;
