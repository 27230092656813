import React from "react";
import { styled } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";

const LikesTagWrapper = styled("div")`
  display: inline-flex;
  align-items: center;
  padding: 1px 2px 1px 10px;
  background-color: #e0485c;
  border-radius: 10%;
  color: white;
  position: absolute;
  top: 96px;
  right: 4px;
  font-family: inter, sans-serif;
  font-size: 0.9rem;
  justify-content: flex-end;
`;

const StyledFavoriteIcon = styled(FavoriteIcon)`
  color: white;
  width: 0.9rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
`;

const LikesTag = ({ likes }) => {
  return (
    <LikesTagWrapper>
      {likes}
      <StyledFavoriteIcon />
    </LikesTagWrapper>
  );
};

export default LikesTag;
