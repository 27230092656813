import styled from "styled-components";

export const StyledPosition = styled.img`
  width: 35px;
  opacity: ${({ $active, $noneSelected }) =>
    $noneSelected ? 1 : $active ? 1 : 0.5};

  &:hover {
    opacity: 1 !important;
  }
`;
