import React, { useState } from "react";
import { Drawer, Stack } from "@mui/material";
import { QuestionMark } from "@mui/icons-material";
import {
  StyledContentContainer,
  StyledInfoDial,
  StyledRemainingLikesDial,
  Subtitle,
  TitleBar1,
  TitleBar2,
} from "./Sidebuttons.styles";
import LSLogo from "../../assets/ls_logo.png";
import FHLogo from "../../assets/fh_logo.png";
import background from "../../assets/bg.png";
import LogoPopover from "../LogoPopover/LogoPopover";
import RemainingLikes from "../RemainingVotes/RemainingLikes";
import { useUser } from "../../contexts/UserContext";

function SideButtons() {
  const { user } = useUser();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerContent = (
    <StyledContentContainer $background={background}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        marginTop={5}
      >
        <LogoPopover
          src={LSLogo}
          alt={"LS logo"}
          size={"70px"}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          popoverContent={"LS Team"}
        />
        <LogoPopover
          src={FHLogo}
          alt={"FH logo"}
          size={"55px"}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          popoverContent={"FH Team"}
        />
      </Stack>
      <Subtitle>
        To use LS or FH scripts, simply select the champion. Everything is
        already pre-configured in the core loader.
      </Subtitle>
      <Subtitle $fontWeight={"500"} $isBold={true}>
        {" "}
        Nothing to do on your part
      </Subtitle>
      <TitleBar1 />
      <TitleBar2 />
      {/*      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        marginTop={3}
      >
        <LogoPopover
          src={RSLogo}
          alt={"RS logo"}
          size={"65px"}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          popoverContent={"RS Team"}
        />
      </Stack>
      <Subtitle>
        Champions displaying the RS logo are developed by a third party and
        require a separate purchase, not included in your LS subscription.
      </Subtitle>
      <Subtitle>
        <CopiableText
          text="rs7666666."
          placeHolder={"Add RS seller on discord"}
        />
      </Subtitle>*/}
    </StyledContentContainer>
  );

  return (
    <>
      {user && (
        <StyledRemainingLikesDial
          icon={<RemainingLikes />}
          ariaLabel={"Remaining likes"}
        />
      )}
      <StyledInfoDial
        ariaLabel="Open info drawer"
        direction={"left"}
        icon={<QuestionMark />}
        onClick={() => setDrawerOpen(true)}
      />
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerContent}
      </Drawer>
    </>
  );
}

export default SideButtons;
