import styled from "styled-components";
import { Grid as MUIGrid, IconButton } from "@mui/material";

export const ResponsiveGrid = styled(MUIGrid)`
  margin-top: 1.5rem;
  flex-direction: column !important;
  align-items: center !important;
  align-content: center !important;

  @media screen and (min-width: 1185px) {
    flex-direction: row !important;
    justify-content: center;
  }
`;

export const StyledIconButton = styled(IconButton)`
  margin-right: 0.7rem !important;
  width: 60px;
  height: 60px;
  background-color: grey !important;

  &:hover {
    background-color: #797979 !important;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 50px;
  padding: 10px 20px;
  width: 25rem;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  margin-right: 2rem;
  margin-bottom: 0;

  @media screen and (max-width: 1184px) {
    width: 20rem;
    margin-bottom: 2rem;
    margin-right: 0;
  }
`;

export const SearchInput = styled.input`
  flex: 1;
  border: none;
  padding: 0 10px;
  font-family: Inter, sans-serif !important;
  font-weight: 500;
  border-radius: 50px;
  outline: none;
  background-color: transparent;
  &::placeholder {
    font-size: 1.1rem;

    @media (max-width: 1184px) {
      font-size: 0.8rem;
    }
  }
`;

export const SearchButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    color: #555;
  }
`;
