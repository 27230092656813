import styled from "styled-components";
import { Popover } from "@mui/material";

export const StyledLogo = styled("img")`
  max-width: ${({ size }) => size || "40px"};
`;

export const StyledPopover = styled(Popover)`
  & .MuiPopover-paper {
    margin-top: 10px;
    background-color: black;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    color: white;
  }
`;
