import React from "react";
import { IconButton, styled } from "@mui/material";
import uggLogo from "../assets/ugg_logo.png";

const UggButton = styled(IconButton)`
  position: absolute;
  top: 3px;
  right: 84px;
  background-color: rgba(93, 123, 185, 0.42);
  border-radius: 10px;

  &:hover {
    background-color: rgba(15, 69, 181, 0.42);
  }
`;

const UggLink = ({ champion }) => {
  const uggUrl = `https://u.gg/lol/champions/${champion.name.toLowerCase()}/build`;

  return (
    <a href={uggUrl} target="_blank" rel="noopener noreferrer">
      <UggButton aria-label="image">
        <img
          src={uggLogo}
          style={{ width: "22px", height: "25px" }}
          alt={"ugg link"}
        />
      </UggButton>
    </a>
  );
};

export default UggLink;
