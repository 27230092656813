import { List, ListItem } from "@mui/material";
import {
  ConfigItemText,
  ModalBox,
  ModalContainer,
  ModalHeader,
} from "./Modal.styles";

export default function InfoModal({ open, onClose, champion }) {
  return (
    <ModalContainer open={open} onClose={onClose}>
      <ModalBox>
        <List>
          {champion?.warning && (
            <>
              <ModalHeader variant="h6">Warning</ModalHeader>
              <ListItem>
                <ConfigItemText primary={champion.warning} />
              </ListItem>
            </>
          )}
          {champion?.config && (
            <>
              <ModalHeader variant="h6">Recommended Settings</ModalHeader>
              {Object.entries(champion.config).map(([key, value]) => (
                <ListItem key={key}>
                  <ConfigItemText primary={`${key}: ${value}`} />
                </ListItem>
              ))}
            </>
          )}
        </List>
      </ModalBox>
    </ModalContainer>
  );
}
