import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import { StyledLogo, StyledPopover } from "./LogoPopover.styles";

function LogoPopover({
  src,
  alt,
  size,
  anchorOrigin,
  transformOrigin,
  popoverContent,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <StyledLogo
        size={size}
        src={src}
        alt={alt}
        aria-owns={openPopover ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <StyledPopover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={openPopover}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{popoverContent}</Typography>
      </StyledPopover>
    </Box>
  );
}

export default LogoPopover;
