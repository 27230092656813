import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { ButtonGroup, Divider } from "@mui/material";
import {
  SearchButton,
  SearchContainer,
  SearchInput,
  ResponsiveGrid,
} from "./Search.styles";

import SortOrderButtons from "./SortOrderButtons/SortOrderButtons";
import PositionButtons from "./PositionButtons/PositionButtons";
import { useChampionSearch } from "../../contexts/ChampionSearchContext";

const Search = () => {
  const {
    searchTerm,
    setSearchTerm,
    selectedPosition,
    sortOrder,
    handlePositionChange,
    handleScoreSort,
  } = useChampionSearch();

  return (
    <ResponsiveGrid container>
      <SearchContainer>
        <SearchInput
          type="text"
          placeholder="Search by name, LS or FH"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ zIndex: "20" }}
        />
        <SearchButton>
          <SearchIcon />
        </SearchButton>
      </SearchContainer>
      <ButtonGroup
        aria-label="positions"
        sx={{
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <PositionButtons
          selectedPosition={selectedPosition}
          handlePositionChange={handlePositionChange}
        />
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            borderColor: "transparent",
            marginRight: "0.7rem !important",
          }}
        />
        <SortOrderButtons
          sortOrder={sortOrder}
          handleScoreSort={handleScoreSort}
        />
      </ButtonGroup>
    </ResponsiveGrid>
  );
};

export default Search;
