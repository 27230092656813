import { RoundMUIBadge, StyledIconText } from "./Badge.styles";

function RoundBadge({ icon, text, sx, $badgeSize, $borderRadius }) {
  return (
    <RoundMUIBadge
      $badgeSize={$badgeSize}
      $borderRadius={$borderRadius}
      variant="dot"
      sx={{
        position: "absolute",
        ...sx,
      }}
    >
      {text && <StyledIconText>{text}</StyledIconText>}
      {icon}
    </RoundMUIBadge>
  );
}

export default RoundBadge;
