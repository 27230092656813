import React, { useCallback } from "react";
import { StyledAppBar, StyledLogo } from "./NavBar.styles";
import logo from "../../assets/logo.png";
import { Box, Button, Toolbar } from "@mui/material";
import { useUser } from "../../contexts/UserContext";
import styled from "styled-components";

const StyledUserGreetings = styled.span`
  font-family: Inter, sans-serif;
  font-weight: normal;
  font-size: 1.1rem;
  margin-right: 1rem;
`;

const StyledUserBadge = styled(Box)`
  margin-left: auto;
  display: flex !important;
  align-content: center !important;
  align-items: center !important;
`;

const StyledLoginButton = styled(Button)`
  margin-left: auto !important;
  font-family: Inter, sans-serif !important;
  font-size: 1rem !important;
  font-weight: normal !important;
`;

const NavBar = () => {
  const {
    user,
    actions: { logout, login },
  } = useUser();

  const logoutUser = useCallback(() => {
    logout.mutateAsync().then();
  }, [logout]);

  return (
    <Box sx={{ width: "100%" }}>
      <StyledAppBar position="static">
        <Toolbar>
          <StyledLogo src={logo} />
          {user ? (
            <StyledUserBadge>
              <StyledUserGreetings>{`Welcome back ${user.userName} !`}</StyledUserGreetings>
              <StyledLoginButton color="inherit" onClick={logoutUser}>
                Logout
              </StyledLoginButton>
            </StyledUserBadge>
          ) : (
            <StyledLoginButton color="inherit" onClick={login}>
              Login
            </StyledLoginButton>
          )}
        </Toolbar>
      </StyledAppBar>
    </Box>
  );
};

export default NavBar;
