export function handleQueryError(setError, message) {
  return (error) => {
    if (error.response && error.response.status !== 500) {
      setError(error.response.data.message);
    } else {
      setError(
        `An error occurred while ${message}.\n Please contact aex on discord.`,
      );
    }
  };
}
