import React from "react";
import { StyledPosition } from "./PositionButtons.styles";
import * as positions from "../../../assets/positions";
import { StyledIconButton } from "../Search.styles";

const PositionButtons = ({ selectedPosition, handlePositionChange }) => {
  const positionKeys = ["Top", "Mid", "Jungle", "Bot", "Support"];
  const buttons = positionKeys.map((key) => (
    <StyledIconButton
      key={key}
      onClick={() => handlePositionChange(key.toLowerCase())}
    >
      <StyledPosition
        $active={selectedPosition === key.toLowerCase()}
        $noneSelected={!selectedPosition}
        src={positions[key.toLowerCase()]}
        alt={key.toLowerCase()}
      />
    </StyledIconButton>
  ));

  return <>{buttons}</>;
};

export default PositionButtons;
