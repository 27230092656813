import styled from "styled-components";
import { ListItemText, Modal, Typography } from "@mui/material";

export const ModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalBox = styled.div`
  width: 100%;
  max-width: 360px;
  background-color: #2a2a2a;
  margin: auto;
`;

export const ModalHeader = styled(Typography)`
  color: white;
  padding: 16px;
`;

export const ConfigItemText = styled(ListItemText)`
  color: white;
`;
