import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  CardTitle,
  StyledSettingsIcon,
  StyledWarningIcon,
  LikeButton,
} from "./ChampionCard.styles.jsx";
import RoundBadge from "../Badge/RoundBadge";
import { Box } from "@mui/material";
import { Favorite } from "@mui/icons-material";
import LikesTag from "../LikesTag";
import UggLink from "../UggLink";
import TeamChips from "../TeamChips";

const ChampionCard = React.memo(
  ({ champion, onCardClick, onClickLike, likes }) => {
    return (
      <Box position="relative">
        <Card>
          <CardMedia
            onClick={onCardClick}
            image={champion.imageUrl}
            title={champion.name}
            sx={{
              cursor:
                champion.config || champion.warning ? "pointer" : "default",
            }}
          />
          <CardContent sx={{ minHeight: "100px", paddingBottom: "5px" }}>
            <TeamChips champion={champion} />
            <CardTitle>{champion.name}</CardTitle>
            <LikeButton aria-label="Like" onClick={onClickLike}>
              <Favorite />
            </LikeButton>
          </CardContent>
        </Card>
        {champion.warning && (
          <RoundBadge sx={{ top: 5, right: 5 }} icon={<StyledWarningIcon />} />
        )}
        {champion.config && (
          <RoundBadge sx={{ top: 5, right: 5 }} icon={<StyledSettingsIcon />} />
        )}
        <LikesTag likes={likes} />
        <UggLink champion={champion} />
      </Box>
    );
  },
);

export default ChampionCard;
