import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import axios from "axios";
import { useError } from "./ErrorContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleQueryError } from "../utils/queryUtils";
import { CHAMPIONS_DATA_QK } from "./ChampionDataContext";

export const UserContext = createContext(null);

const LOGGED_IN_STATUS_QK = "loggedInStatus";

const USER_DATA_QK = "userData";
const USER_VOTES_QK = "userVotes";
export const UserProvider = ({ children }) => {
  const queryClient = useQueryClient();

  const {
    actions: { setError },
  } = useError();

  const login = useCallback(() => {
    window.location.href = process.env.REACT_APP_DISCORD_REDIRECT_URI;
  }, []);

  const logout = useMutation(
    async () => await axios.post("/.netlify/functions/userFunctions/logout"),
    {
      onSuccess: () => {
        queryClient.setQueryData(USER_DATA_QK, null);
        queryClient.setQueryData(LOGGED_IN_STATUS_QK, false);
      },
    },
  );

  const { data: loggedInStatus, isFetching: isFetchingLoginStatus } = useQuery(
    LOGGED_IN_STATUS_QK,
    async () => {
      const response = await axios.get(
        "/.netlify/functions/userFunctions/getLoggedinStatus",
      );
      return response.data;
    },
    {
      onError: handleQueryError(setError, "fetching login status"),
      refetchOnWindowFocus: false,
      retry: false, // Don't retry on failure
    },
  );

  const { data: user } = useQuery(
    USER_DATA_QK,
    async () => {
      const response = await axios.get(
        "/.netlify/functions/userFunctions/getUserData",
      );
      return response.data.userData;
    },
    {
      enabled: !isFetchingLoginStatus && !!loggedInStatus,
      onError: handleQueryError(setError, "fetching user data"),
      refetchOnWindowFocus: false,
    },
  );

  const { data: userVotes } = useQuery(
    USER_VOTES_QK,
    async () => {
      const response = await axios.get(
        "/.netlify/functions/userFunctions/getUserVotes",
      );
      return response.data.userVotes;
    },
    {
      onError: handleQueryError(setError, "getting user liked champions"),
      refetchOnWindowFocus: false,
      enabled: !isFetchingLoginStatus && !!loggedInStatus,
    },
  );

  const postUserLike = useMutation(
    async ({ champion, teamName }) => {
      return await axios.post(
        "/.netlify/functions/userFunctions/postUserLike",
        {
          championName: champion.name,
          teamName,
        },
      );
    },
    {
      onSuccess: () => {
        if (user && user.likesRemaining > 0) {
          const updatedUser = {
            ...user,
            likesRemaining: user.likesRemaining - 1,
          };

          queryClient.setQueryData(USER_DATA_QK, updatedUser);
        }

        queryClient.invalidateQueries(USER_VOTES_QK).then();
        queryClient.invalidateQueries(CHAMPIONS_DATA_QK).then();
      },
      refetchOnWindowFocus: false,
      onError: handleQueryError(setError, "liking a champion"),
    },
  );

  useEffect(() => {
    if (window.location.search.includes("code")) {
      const newURL =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
      window.history.replaceState({}, document.title, newURL);
    }
  }, []);

  const contextValue = {
    user,
    userVotes,
    actions: {
      login,
      logout,
      postUserLike,
    },
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
