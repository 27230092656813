import styled from "styled-components";
import { Box, SpeedDial } from "@mui/material";
import RemainingLikes from "../RemainingVotes/RemainingLikes";

export const Subtitle = styled.h2`
  font-weight: ${({ $fontWeight }) => $fontWeight || "100"};
  font-family: Inter, sans-serif !important;

  font-size: ${({ $fontSize }) => $fontSize || "1rem"};
  color: #ebeff0;
  line-height: 2rem;
  text-align: ${({ $textAlign }) => $textAlign || "center"};

  @media screen and (min-width: 600px) and (max-width: 1023px) {
    font-size: ${({ $fontSize }) =>
      `calc(${$fontSize || "1rem"} * 1.2)`}; // 20% more
  }

  @media screen and (min-width: 1024px) {
    font-size: ${({ $fontSize }) =>
      `calc(${$fontSize || "1rem"} * 1.3)`}; // 30% more
  }
`;

export const StyledRemainingLikesDial = styled(SpeedDial)`
  & {
    position: fixed;
    bottom: 120px;
    right: 16px;
  }

  & .MuiFab-root {
    background: linear-gradient(to right, #db4058, #e03464);
    cursor: default;
    pointer-events: none;
  }
`;

export const StyledInfoDial = styled(SpeedDial)`
  & {
    position: fixed;
    bottom: 50px;
    right: 16px;
    color: #393939;
  }

  & .MuiFab-root {
    background: linear-gradient(to right, #db4058, #e03464);
  }

  & .MuiFab-root:hover {
    background: linear-gradient(
      to right,
      rgba(219, 64, 88, 0.8),
      rgba(224, 52, 100, 0.8)
    );
  }
`;

export const StyledRemainingLikes = styled(RemainingLikes)`
  position: fixed;
  bottom: 200px;
  right: 16px;
  color: #393939;
`;

export const StyledContentContainer = styled(Box)`
  & {
    padding: 30px;
    background-color: #393939;
    max-width: 300px;
    height: 100%;
    background-image: url(${(props) => props.$background});
    background-size: auto;
    background-position: center;
    background-repeat: repeat;
  }
`;

export const TitleBar1 = styled.div`
  width: 60%;
  height: 2px;
  background-color: #e0485c;
  margin: 0.5rem auto 0;
`;

export const TitleBar2 = styled(TitleBar1)`
  width: 30%;
  margin: 4px auto 0;
`;
