import React from "react";
import ChampionGrid from "./components/ChampionGrid/ChampionGrid";
import background from "./assets/bg.png";

import {
  AppContainer,
  GlobalStyle,
  MainTitle,
  SupportedText,
  TitleBar1,
  TitleBar2,
} from "./App.styles";
import Search from "./components/Search/Search";
import NavBar from "./components/NavBar/NavBar";
import { Box } from "@mui/material";
import { UserProvider } from "./contexts/UserContext";
import { ChampionDataProvider } from "./contexts/ChampionDataContext";
import { ChampionSearchProvider } from "./contexts/ChampionSearchContext";
import AlertBanner from "./components/AlertBanner/AlertBanner";
import SideButtons from "./components/SideButtons/SideButtons";
import { ErrorProvider } from "./contexts/ErrorContext";
import { QueryClient, QueryClientProvider } from "react-query";
import Snowfall from "react-snowfall";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorProvider>
        <UserProvider>
          <ChampionDataProvider>
            <ChampionSearchProvider>
              <GlobalStyle />
              <AppContainer $background={background}>
                <div
                  style={{ width: "100vw", height: "100vh", position: "fixed" }}
                >
                  <Snowfall />
                </div>
                <NavBar />
                <AlertBanner />
                <Box sx={{ padding: "50px" }}>
                  <MainTitle>
                    <SupportedText>Supported </SupportedText>
                    Champions List
                  </MainTitle>
                  <TitleBar1 />
                  <TitleBar2 />
                  <Search />
                  <ChampionGrid />
                </Box>
                <SideButtons />
              </AppContainer>
            </ChampionSearchProvider>
          </ChampionDataProvider>
        </UserProvider>
      </ErrorProvider>
    </QueryClientProvider>
  );
}

export default App;
