import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333333;
  background-image: url(${(props) => props.$background});
  background-size: auto;
  background-position: center;
  background-repeat: repeat;
  min-height: 100vh;
  min-width: 500px;
`;

export const MainTitle = styled.h1`
  font-size: 2.625rem;
  font-weight: 800;
  margin-bottom: 1rem !important;
  text-align: center;
  color: #fbfbfb;
  font-family: inter, sans-serif;
  line-height: 1.25;
  letter-spacing: -0.02em;

  @media screen and (min-width: 600px) and (max-width: 1023px) {
    font-size: 2.5rem;
  }

  @media screen and (min-width: 1024px) {
    font-size: 3rem;
  }
`;

export const SupportedText = styled.span`
  background: linear-gradient(
    to right,
    #db4058,
    #e03464
  ); /* darkColor, lightColor */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const TitleBar1 = styled.div`
  width: 30%;
  height: 2px;
  background: linear-gradient(to right, #db4058, #e03464);
  margin: 0.5rem auto 0;
`;

export const TitleBar2 = styled(TitleBar1)`
  width: 20%;
  margin: 4px auto 0;
  background: linear-gradient(to right, #db4058, #e03464);
`;

export const GlobalStyle = createGlobalStyle`
    /* Works on Chrome/Edge/Safari */
    *::-webkit-scrollbar {
        width: 15px;
    }

    *::-webkit-scrollbar-track {
        background: #151515;
    }

    *::-webkit-scrollbar-thumb {
        background-color: #333333;
        border-radius: 5px;
        border: 3px solid #333333;
    }

    *::-webkit-scrollbar-thumb:hover {
        background-color: #565656FF; /* Color of your choice */
        border: 3px solid #565656FF;
    }
    
    body{
        padding: 0 !important;
        overflow: visible !important;
    }
`;
