import styled from "styled-components";
import { Badge } from "@mui/material";

export const RoundMUIBadge = styled(Badge)`
  & .MuiBadge-badge {
    padding: ${({ $badgeSize }) => $badgeSize || "10px"};
    border-radius: ${({ $borderRadius }) => $borderRadius || "50%"};
    background-color: #e0485c;
  }
`;

export const StyledIconText = styled("span")`
  & {
    z-index: 10;
    position: relative;
    top: -9px;
    right: 2px;
    color: white;
    font-family: Inter, sans-serif !important;
    font-weight: 500;
    font-size: 0.9rem;
  }
`;
