import { createContext, useContext, useReducer } from "react";

export const ErrorContext = createContext(null);

const errorReducer = (state, action) => {
  switch (action.type) {
    case "SET_ERROR":
      return action.error;
    case "CLEAR_ERROR":
      return null;
    default:
      return state;
  }
};

export const ErrorProvider = ({ children }) => {
  const [error, dispatch] = useReducer(errorReducer, null);

  const setError = (error) => {
    dispatch({ type: "SET_ERROR", error });
  };

  const clearError = () => {
    dispatch({ type: "CLEAR_ERROR" });
  };

  const contextValue = {
    error,
    actions: {
      setError,
      clearError,
    },
  };

  return (
    <ErrorContext.Provider value={contextValue}>
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = () => useContext(ErrorContext);
