import { ModalBox, ModalContainer, ModalHeader } from "./Modal.styles";
import {
  Avatar,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import { LikeButton } from "../ChampionCard/ChampionCard.styles";
import rsLogo from "../../assets/rs_logo.png";
import lsLogo from "../../assets/ls_logo.png";
import fhLogo from "../../assets/fh_logo.png";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import React, { useCallback } from "react";
import { useUser } from "../../contexts/UserContext";
import styled from "styled-components";
import { useChampionData } from "../../contexts/ChampionDataContext";
import { useError } from "../../contexts/ErrorContext";

const TEAMS = ["ls", "fh", "rs"];
const logoPaths = { rs: rsLogo, ls: lsLogo, fh: fhLogo };
const logoSizes = { width: 35, height: 35 };

const StyledAvatar = styled(Avatar)`
  img {
    object-fit: contain;
  }
`;
const LikesModal = ({ open, onClose, champion }) => {
  const {
    user,
    userVotes,
    actions: { postUserLike },
  } = useUser();
  const {
    actions: { setError },
  } = useError();
  const { championTeamLikes } = useChampionData();

  const handleLikeClick = useCallback(
    (teamName) => {
      if (!user) {
        setError("You must be logged in to like a champion!");
        return;
      }

      postUserLike.mutateAsync({ champion, teamName }).then();
    },
    [champion, postUserLike, setError, user],
  );

  const calculateTeamLikes = useCallback(
    (team) => {
      return (championTeamLikes[champion.name] || {})[team] || 0;
    },
    [champion, championTeamLikes],
  );

  const checkIfLiked = useCallback(
    (team) => {
      return (
        user &&
        userVotes.findIndex(
          (like) =>
            like.userId === user.userId &&
            like.championName === champion.name &&
            like.teamName === team,
        ) !== -1
      );
    },
    [champion, userVotes, user],
  );

  if (!champion) return null;

  return (
    <ModalContainer open={open} onClose={onClose}>
      <ModalBox>
        <List>
          <ModalHeader variant="h6" textAlign={"center"}>
            Champion Likes
          </ModalHeader>
          {TEAMS.map((team) => {
            if (!champion.hasOwnProperty(team)) return null;

            const teamLikes = calculateTeamLikes(team);
            const isLiked = checkIfLiked(team);

            return (
              <ListItem key={team}>
                <ListItemAvatar>
                  <StyledAvatar
                    src={logoPaths[team]}
                    variant="square"
                    sx={logoSizes}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={`${team.toUpperCase()} Team`}
                  secondary={`Likes: ${teamLikes}`}
                  primaryTypographyProps={{ style: { color: "white" } }}
                  secondaryTypographyProps={{ style: { color: "grey" } }}
                />
                <ListItemSecondaryAction>
                  <LikeButton
                    aria-label="Like team's champion"
                    onClick={() => handleLikeClick(team)}
                    disabled={postUserLike.isLoading}
                  >
                    {postUserLike.isLoading ? (
                      <CircularProgress size={22} color="inherit" />
                    ) : isLiked ? (
                      <Favorite />
                    ) : (
                      <FavoriteBorder />
                    )}
                  </LikeButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </ModalBox>
    </ModalContainer>
  );
};

export default LikesModal;
