import React, { useCallback, useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import { Box, Collapse, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useError } from "../../contexts/ErrorContext";

const AlertBanner = () => {
  const {
    error,
    actions: { clearError },
  } = useError();
  const [open, setOpen] = useState(true);

  const handleClose = useCallback(() => {
    setOpen(false);
    clearError();
  }, [clearError]);

  useEffect(() => {
    if (error) {
      setOpen(true);

      const timer = setTimeout(() => {
        handleClose();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [error, handleClose]);

  return (
    error && (
      <Box
        sx={{
          width: "70%",
          position: "fixed",
          bottom: 0,
          left: "15%", // Centering: (100% - 70%) / 2
          right: "15%", // Centering: (100% - 70%) / 2
          zIndex: 100,
          marginBottom: 2,
        }}
      >
        <Collapse in={open}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
            sx={{
              backgroundColor: "rgb(22, 11, 11)",
              color: "rgb(244, 199, 199)",
            }}
          >
            {error}
          </Alert>
        </Collapse>
      </Box>
    )
  );
};

export default AlertBanner;
