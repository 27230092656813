import styled from "styled-components";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  IconButton,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { Favorite, Warning } from "@mui/icons-material";

export const StyledSettingsIcon = styled(SettingsIcon)`
  top: -8px;
  position: absolute;
  z-index: 10;
  right: -8px;
  color: white;
  font-size: 1rem !important;
  transition: transform 0.3s ease-in-out !important;
`;

export const StyledWarningIcon = styled(Warning)`
  top: -8px;
  position: absolute;
  z-index: 10;
  right: -7px;
  color: white;
  font-size: 0.9rem !important;
`;

export const StyledFavoriteIcon = styled(Favorite)`
  top: -8px;
  position: absolute;
  z-index: 10;
  right: -15px;
  color: white;
  font-size: 0.9rem !important;
`;

export const Card = styled(MuiCard)`
  width: 125px;
  max-width: 125px;
  &:hover + span svg {
    transform: rotate(360deg);
  }
`;

export const CardMedia = styled(MuiCardMedia)`
  height: 125px;
  width: 125px;
`;

export const CardTitle = styled.span`
  font-family: Inter, sans-serif !important;
  font-weight: 500;
  font-size: clamp(0.8rem, 2vw, 1rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
  display: block;
  margin: 15px auto 5px auto;
  color: #fbfbfb;
`;

export const CardContent = styled(MuiCardContent)`
  text-align: center;
  background-color: black;
  padding-left: 2px !important;
  padding-right: 2px !important;
  min-height: 20px;
`;

export const LikeButton = styled(IconButton)`
  color: #e0485c !important;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.25) !important;
  }
`;
