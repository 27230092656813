import styled from "styled-components";
import { AppBar } from "@mui/material";

export const StyledLogo = styled.img`
  width: 200px;
`;

export const StyledAppBar = styled(AppBar)`
  background: linear-gradient(to right, #db4058, #e03464);
`;
