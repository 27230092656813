import Aatrox from "../assets/champions/Aatrox.png";
import Ahri from "../assets/champions/Ahri.png";
import Akali from "../assets/champions/Akali.png";
import Akshan from "../assets/champions/Akshan.png";
import Alistar from "../assets/champions/Alistar.png";
import Amumu from "../assets/champions/Amumu.png";
import Anivia from "../assets/champions/Anivia.png";
import Annie from "../assets/champions/Annie.png";
import Aphelios from "../assets/champions/Aphelios.png";
import Ashe from "../assets/champions/Ashe.png";
import AurelionSol from "../assets/champions/AurelionSol.png";
import Azir from "../assets/champions/Azir.png";
import Bard from "../assets/champions/Bard.png";
import Belveth from "../assets/champions/Belveth.png";
import Blitzcrank from "../assets/champions/Blitzcrank.png";
import Brand from "../assets/champions/Brand.png";
import Briar from "../assets/champions/Briar.png";
import Caitlyn from "../assets/champions/Caitlyn.png";
import Camille from "../assets/champions/Camille.png";
import Cassiopeia from "../assets/champions/Cassiopeia.png";
import Chogath from "../assets/champions/Chogath.png";
import Corki from "../assets/champions/Corki.png";
import Darius from "../assets/champions/Darius.png";
import Diana from "../assets/champions/Diana.png";
import Draven from "../assets/champions/Draven.png";
import DrMundo from "../assets/champions/DrMundo.png";
import Ekko from "../assets/champions/Ekko.png";
import Elise from "../assets/champions/Elise.png";
import Evelynn from "../assets/champions/Evelynn.png";
import Ezreal from "../assets/champions/Ezreal.png";
import Fiddlesticks from "../assets/champions/Fiddlesticks.png";
import Fiora from "../assets/champions/Fiora.png";
import Fizz from "../assets/champions/Fizz.png";
import Galio from "../assets/champions/Galio.png";
import Gangplank from "../assets/champions/Gangplank.png";
import Garen from "../assets/champions/Garen.png";
import Gnar from "../assets/champions/Gnar.png";
import Gragas from "../assets/champions/Gragas.png";
import Graves from "../assets/champions/Graves.png";
import Gwen from "../assets/champions/Gwen.png";
import Hecarim from "../assets/champions/Hecarim.png";
import Heimerdinger from "../assets/champions/Heimerdinger.png";
import Hwei from "../assets/champions/Hwei.png";
import Illaoi from "../assets/champions/Illaoi.png";
import Irelia from "../assets/champions/Irelia.png";
import Janna from "../assets/champions/Janna.png";
import JarvanIV from "../assets/champions/JarvanIV.png";
import Jax from "../assets/champions/Jax.png";
import Jayce from "../assets/champions/Jayce.png";
import Jhin from "../assets/champions/Jhin.png";
import Jinx from "../assets/champions/Jinx.png";
import Kaisa from "../assets/champions/Kaisa.png";
import Kalista from "../assets/champions/Kalista.png";
import Karma from "../assets/champions/Karma.png";
import Karthus from "../assets/champions/Karthus.png";
import Kassadin from "../assets/champions/Kassadin.png";
import Katarina from "../assets/champions/Katarina.png";
import Kayle from "../assets/champions/Kayle.png";
import Kayn from "../assets/champions/Kayn.png";
import Kennen from "../assets/champions/Kennen.png";
import Khazix from "../assets/champions/Khazix.png";
import Kindred from "../assets/champions/Kindred.png";
import Kled from "../assets/champions/Kled.png";
import KogMaw from "../assets/champions/KogMaw.png";
import KSante from "../assets/champions/KSante.png";
import Leblanc from "../assets/champions/Leblanc.png";
import LeeSin from "../assets/champions/LeeSin.png";
import Leona from "../assets/champions/Leona.png";
import Lillia from "../assets/champions/Lillia.png";
import Lissandra from "../assets/champions/Lissandra.png";
import Lucian from "../assets/champions/Lucian.png";
import Lulu from "../assets/champions/Lulu.png";
import Lux from "../assets/champions/Lux.png";
import Malphite from "../assets/champions/Malphite.png";
import Malzahar from "../assets/champions/Malzahar.png";
import Maokai from "../assets/champions/Maokai.png";
import MasterYi from "../assets/champions/MasterYi.png";
import Milio from "../assets/champions/Milio.png";
import MissFortune from "../assets/champions/MissFortune.png";
import MonkeyKing from "../assets/champions/MonkeyKing.png";
import Mordekaiser from "../assets/champions/Mordekaiser.png";
import Morgana from "../assets/champions/Morgana.png";
import Naafiri from "../assets/champions/Naafiri.png";
import Nami from "../assets/champions/Nami.png";
import Nasus from "../assets/champions/Nasus.png";
import Nautilus from "../assets/champions/Nautilus.png";
import Neeko from "../assets/champions/Neeko.png";
import Nidalee from "../assets/champions/Nidalee.png";
import Nilah from "../assets/champions/Nilah.png";
import Nocturne from "../assets/champions/Nocturne.png";
import Nunu from "../assets/champions/Nunu.png";
import Olaf from "../assets/champions/Olaf.png";
import Orianna from "../assets/champions/Orianna.png";
import Pantheon from "../assets/champions/Pantheon.png";
import Pyke from "../assets/champions/Pyke.png";
import Qiyana from "../assets/champions/Qiyana.png";
import Quinn from "../assets/champions/Quinn.png";
import Rammus from "../assets/champions/Rammus.png";
import RekSai from "../assets/champions/RekSai.png";
import Renata from "../assets/champions/Renata.png";
import Renekton from "../assets/champions/Renekton.png";
import Rengar from "../assets/champions/Rengar.png";
import Riven from "../assets/champions/Riven.png";
import Rumble from "../assets/champions/Rumble.png";
import Ryze from "../assets/champions/Ryze.png";
import Samira from "../assets/champions/Samira.png";
import Senna from "../assets/champions/Senna.png";
import Seraphine from "../assets/champions/Seraphine.png";
import Sett from "../assets/champions/Sett.png";
import Shaco from "../assets/champions/Shaco.png";
import Shen from "../assets/champions/Shen.png";
import Sion from "../assets/champions/Sion.png";
import Sivir from "../assets/champions/Sivir.png";
import Sona from "../assets/champions/Sona.png";
import Soraka from "../assets/champions/Soraka.png";
import Smolder from "../assets/champions/Smolder.png";
import Swain from "../assets/champions/Swain.png";
import Sylas from "../assets/champions/Sylas.png";
import Syndra from "../assets/champions/Syndra.png";
import TahmKench from "../assets/champions/TahmKench.png";
import Taliyah from "../assets/champions/Taliyah.png";
import Talon from "../assets/champions/Talon.png";
import Taric from "../assets/champions/Taric.png";
import Teemo from "../assets/champions/Teemo.png";
import Thresh from "../assets/champions/Thresh.png";
import Tristana from "../assets/champions/Tristana.png";
import Trundle from "../assets/champions/Trundle.png";
import Tryndamere from "../assets/champions/Tryndamere.png";
import TwistedFate from "../assets/champions/TwistedFate.png";
import Twitch from "../assets/champions/Twitch.png";
/*import Udyr from "../assets/champions/Udyr.png";*/
import Urgot from "../assets/champions/Urgot.png";
import Varus from "../assets/champions/Varus.png";
import Vayne from "../assets/champions/Vayne.png";
import Veigar from "../assets/champions/Veigar.png";
import Velkoz from "../assets/champions/Velkoz.png";
import Vex from "../assets/champions/Vex.png";
import Vi from "../assets/champions/Vi.png";
import Viego from "../assets/champions/Viego.png";
import Viktor from "../assets/champions/Viktor.png";
import Vladimir from "../assets/champions/Vladimir.png";
import Volibear from "../assets/champions/Volibear.png";
import Warwick from "../assets/champions/Warwick.png";
import Xayah from "../assets/champions/Xayah.png";
import Xerath from "../assets/champions/Xerath.png";
import XinZhao from "../assets/champions/XinZhao.png";
import Yasuo from "../assets/champions/Yasuo.png";
import Yone from "../assets/champions/Yone.png";
import Yorick from "../assets/champions/Yorick.png";
import Yuumi from "../assets/champions/Yuumi.png";
import Zed from "../assets/champions/Zed.png";
import Zeri from "../assets/champions/Zeri.png";
import Ziggs from "../assets/champions/Ziggs.png";
import Zilean from "../assets/champions/Zilean.png";
import Zoe from "../assets/champions/Zoe.png";
import Zyra from "../assets/champions/Zyra.png";

//https://developer.riotgames.com/docs/lol#data-dragon
export const champions = [
  {
    name: "Aatrox",
    position: "Top",
    imageUrl: Aatrox,
    ls: true,
    /*    rs: true,*/
  },
  {
    name: "Ahri",
    position: "Mid",
    imageUrl: Ahri,
    ls: true,
  },
  {
    name: "Akali",
    position: "Mid",
    imageUrl: Akali,
    fh: true,
  },
  {
    name: "Akshan",
    position: "Bot",
    imageUrl: Akshan,
    ls: true,
    /*  rs: true,*/
  },
  {
    name: "Alistar",
    position: "Support",
    imageUrl: Alistar,
    ls: true,
  },
  {
    name: "Amumu",
    position: "Jungle,Support",
    imageUrl: Amumu,
    ls: true,
  },
  {
    name: "Anivia",
    position: "Mid",
    imageUrl: Anivia,
    fh: true,
  },
  {
    name: "Annie",
    position: "Mid,Support",
    imageUrl: Annie,
    fh: true,
  },
  {
    name: "Aphelios",
    position: "Bot",
    imageUrl: Aphelios,
    ls: true,
    /* rs: true,*/
  },
  {
    name: "Ashe",
    position: "Bot,Support",
    imageUrl: Ashe,
    ls: true,
  },
  {
    name: "Aurelion Sol",
    position: "Mid",
    imageUrl: AurelionSol,
    fh: true,
  },
  {
    name: "Azir",
    position: "Mid",
    imageUrl: Azir,
    fh: true,
  },
  {
    name: "Bard",
    position: "Support",
    imageUrl: Bard,
    warning:
      "The FH version is a testing version. Please join the FH Discord server for more information.",
    fh: true,
  },
  {
    name: "Belveth",
    position: "Jungle",
    imageUrl: Belveth,
    ls: true,
  },
  {
    name: "Blitzcrank",
    position: "Support",
    imageUrl: Blitzcrank,
    ls: true,
  },
  {
    name: "Brand",
    position: "Mid,Support",
    imageUrl: Brand,
    ls: true,
  },
  {
    name: "Briar",
    position: "Jungle",
    imageUrl: Briar,
    ls: true,
    fh: true,
    /*  rs: true,*/
  },
  {
    name: "Caitlyn",
    position: "Bot",
    imageUrl: Caitlyn,
    ls: true,
    /*    rs: true,*/
  },
  {
    name: "Camille",
    position: "Top,Jungle",
    imageUrl: Camille,
    fh: true,
  },
  {
    name: "Cassiopeia",
    position: "Mid",
    imageUrl: Cassiopeia,
    ls: true,
  },
  {
    name: "Chogath",
    position: "Mid,Support",
    imageUrl: Chogath,
    ls: true,
  },
  {
    name: "Corki",
    position: "Mid,Bot",
    imageUrl: Corki,
    ls: true,
  },
  {
    name: "Darius",
    position: "Top",
    imageUrl: Darius,
    ls: true,
  },
  {
    name: "Diana",
    position: "Mid,Jungle",
    imageUrl: Diana,
    fh: true,
  },
  {
    name: "Draven",
    position: "Bot",
    imageUrl: Draven,
    ls: true,
  },
  {
    name: "Dr Mundo",
    position: "Top,Jungle",
    imageUrl: DrMundo,
    ls: true,
  },
  {
    name: "Ekko",
    position: "Mid,Jungle",
    imageUrl: Ekko,
    ls: true,
    fh: true,
    warning:
      "The FH version is a testing version. Please join the FH Discord server for more information.",
  },
  {
    name: "Elise",
    position: "Jungle",
    imageUrl: Elise,
    ls: true,
  },
  {
    name: "Evelynn",
    position: "Jungle",
    imageUrl: Evelynn,
    ls: true,
  },
  {
    name: "Ezreal",
    position: "Mid,Bot",
    imageUrl: Ezreal,
    warning: "FH version has to be loaded from LUA manager in main menu",
    ls: true,
    fh: true,
    /*    rs: true,*/
  },
  {
    name: "Fiddlesticks",
    position: "Jungle",
    imageUrl: Fiddlesticks,
    ls: true,
  },
  {
    name: "Fiora",
    position: "Top",
    imageUrl: Fiora,
    fh: true,
  },
  {
    name: "Fizz",
    position: "Mid",
    imageUrl: Fizz,
    ls: true,
  },
  {
    name: "Galio",
    position: "Mid,Support",
    imageUrl: Galio,
    ls: true,
  },
  {
    name: "Gangplank",
    position: "Top",
    imageUrl: Gangplank,
    config: {
      "R killsteal on enemies": "Show warning only",
    },
    fh: true,
  },
  {
    name: "Garen",
    position: "Top",
    imageUrl: Garen,
    ls: true,
  },
  {
    name: "Gnar",
    position: "Top",
    imageUrl: Gnar,
    ls: true,
  },
  {
    name: "Gragas",
    position: "Jungle",
    imageUrl: Gragas,
    fh: true,
  },
  {
    name: "Graves",
    position: "Jungle",
    imageUrl: Graves,
    fh: true,
  },
  {
    name: "Gwen",
    position: "Top",
    imageUrl: Gwen,
    ls: true,
  },
  {
    name: "Hecarim",
    position: "Jungle",
    imageUrl: Hecarim,
    ls: true,
  },
  {
    name: "Heimerdinger",
    position: "Mid",
    imageUrl: Heimerdinger,
    fh: true,
  },
  {
    name: "Hwei",
    position: "Mid",
    imageUrl: Hwei,
    fh: true,
  },
  {
    name: "Illaoi",
    position: "Top",
    imageUrl: Illaoi,
    fh: true,
  },
  {
    name: "Irelia",
    position: "Top",
    imageUrl: Irelia,
    ls: true,
    /*rs: true,*/
  },
  {
    name: "Janna",
    position: "Support",
    imageUrl: Janna,
    fh: true,
  },
  {
    name: "Jarvan IV",
    position: "Jungle",
    imageUrl: JarvanIV,
    fh: true,
  },
  {
    name: "Jax",
    position: "Top",
    imageUrl: Jax,
    ls: true,
  },
  {
    name: "Jayce",
    position: "Top",
    imageUrl: Jayce,
    ls: true,
  },
  {
    name: "Jhin",
    position: "Bot",
    imageUrl: Jhin,
    ls: true,
  },
  {
    name: "Jinx",
    position: "Bot",
    imageUrl: Jinx,
    ls: true,
    /*    rs: true,*/
  },
  {
    name: "Kaisa",
    position: "Bot",
    imageUrl: Kaisa,
    ls: true,
  },
  {
    name: "Kalista",
    position: "Bot",
    imageUrl: Kalista,
    ls: true,
    /*  rs: true,*/
  },
  {
    name: "Karma",
    position: "Mid,Support",
    imageUrl: Karma,
    ls: true,
    /*    rs: true,*/
  },
  {
    name: "Karthus",
    position: "Jungle",
    imageUrl: Karthus,
    ls: true,
  },
  {
    name: "Kassadin",
    position: "Mid",
    imageUrl: Kassadin,
    ls: true,
  },
  {
    name: "Katarina",
    position: "Mid",
    imageUrl: Katarina,
    fh: true,
  },
  {
    name: "Kayle",
    position: "Top",
    imageUrl: Kayle,
    ls: true,
  },
  {
    name: "Kayn",
    position: "Jungle",
    imageUrl: Kayn,
    ls: true,
  },
  {
    name: "Kennen",
    position: "Top",
    imageUrl: Kennen,
    ls: true,
  },
  {
    name: "Khazix",
    position: "Jungle",
    imageUrl: Khazix,
    ls: true,
  },
  {
    name: "Kindred",
    position: "Jungle",
    imageUrl: Kindred,
    ls: true,
  },
  {
    name: "Kled",
    position: "Top",
    imageUrl: Kled,
    ls: true,
  },
  {
    name: "KogMaw",
    position: "Bot",
    imageUrl: KogMaw,
    ls: true,
  },
  {
    name: "KSante",
    position: "Top",
    imageUrl: KSante,
    fh: true,
    /* rs: true,*/
  },
  {
    name: "Leblanc",
    position: "Mid",
    imageUrl: Leblanc,
    fh: true,
    /*    rs: true,*/
  },
  {
    name: "Lee Sin",
    position: "Jungle",
    imageUrl: LeeSin,
    ls: true,
  },
  {
    name: "Leona",
    position: "Support",
    imageUrl: Leona,
    ls: true,
  },
  {
    name: "Lillia",
    position: "Jungle",
    imageUrl: Lillia,
    ls: true,
  },
  {
    name: "Lissandra",
    position: "Mid",
    imageUrl: Lissandra,
    ls: true,
  },
  {
    name: "Lucian",
    position: "Bot",
    imageUrl: Lucian,
    ls: true,
  },
  {
    name: "Lulu",
    position: "Support",
    imageUrl: Lulu,
    ls: true,
  },
  {
    name: "Lux",
    position: "Mid,Support",
    imageUrl: Lux,
    ls: true,
  },
  {
    name: "Malphite",
    position: "Top,Mid",
    imageUrl: Malphite,
    ls: true,
  },
  {
    name: "Malzahar",
    position: "Mid",
    imageUrl: Malzahar,
    fh: true,
  },
  {
    name: "Master Yi",
    position: "Jungle",
    imageUrl: MasterYi,
    ls: true,
  },
  {
    name: "Milio",
    position: "Support",
    imageUrl: Milio,
    fh: true,
  },
  {
    name: "Miss Fortune",
    position: "Bot",
    imageUrl: MissFortune,
    ls: true,
  },
  {
    name: "Wukong",
    position: "Top",
    imageUrl: MonkeyKing,
    ls: true,
  },
  {
    name: "Maokai",
    position: "Top,Support",
    imageUrl: Maokai,
    fh: true,
  },
  {
    name: "Mordekaiser",
    position: "Top",
    imageUrl: Mordekaiser,
    ls: true,
  },
  {
    name: "Morgana",
    position: "Mid,Support",
    imageUrl: Morgana,
    ls: true,
  },
  {
    name: "Naafiri",
    position: "Mid",
    imageUrl: Naafiri,
    fh: true,
    /*    rs: true,*/
  },
  {
    name: "Nami",
    position: "Support",
    imageUrl: Nami,
    ls: true,
  },
  {
    name: "Nasus",
    position: "Top",
    imageUrl: Nasus,
    ls: true,
  },
  {
    name: "Nautilus",
    position: "Support",
    imageUrl: Nautilus,
    ls: true,
  },
  {
    name: "Neeko",
    position: "Mid",
    imageUrl: Neeko,
    ls: true,
  },
  {
    name: "Nidalee",
    position: "Jungle",
    imageUrl: Nidalee,
    ls: true,
  },
  {
    name: "Nilah",
    position: "Bot",
    imageUrl: Nilah,
    fh: true,
    /*    rs: true,*/
  },
  {
    name: "Nocturne",
    position: "Jungle",
    imageUrl: Nocturne,
    ls: true,
  },
  {
    name: "Nunu",
    position: "Jungle",
    imageUrl: Nunu,
    ls: true,
  },
  {
    name: "Olaf",
    position: "Top",
    imageUrl: Olaf,
    ls: true,
  },
  {
    name: "Orianna",
    position: "Mid",
    imageUrl: Orianna,
    ls: true,
  },
  {
    name: "Pantheon",
    position: "Top,Support",
    imageUrl: Pantheon,
    ls: true,
  },
  {
    name: "Pyke",
    position: "Support",
    imageUrl: Pyke,
    ls: true,
  },
  {
    name: "Qiyana",
    position: "Jungle",
    imageUrl: Qiyana,
    fh: true,
  },
  {
    name: "Quinn",
    position: "Top",
    imageUrl: Quinn,
    ls: true,
  },
  {
    name: "Rammus",
    position: "Jungle,Support",
    imageUrl: Rammus,
    fh: true,
  },
  {
    name: "RekSai",
    position: "Jungle",
    imageUrl: RekSai,
    ls: true,
  },
  {
    name: "Renata",
    position: "Support",
    imageUrl: Renata,
    ls: true,
  },
  {
    name: "Renekton",
    position: "Top",
    imageUrl: Renekton,
    ls: true,
  },
  {
    name: "Rengar",
    position: "Jungle",
    imageUrl: Rengar,
    ls: true,
  },
  {
    name: "Riven",
    position: "Top",
    imageUrl: Riven,
    fh: true,
  },
  {
    name: "Rumble",
    position: "Top",
    imageUrl: Rumble,
    ls: true,
  },
  {
    name: "Ryze",
    position: "Top",
    imageUrl: Ryze,
    ls: true,
  },
  {
    name: "Samira",
    position: "Bot",
    imageUrl: Samira,
    ls: true,
  },
  {
    name: "Senna",
    position: "Bot,Support",
    imageUrl: Senna,
    ls: true,
  },
  {
    name: "Seraphine",
    position: "Mid,Support",
    imageUrl: Seraphine,
    fh: true,
  },
  {
    name: "Sett",
    position: "Top",
    imageUrl: Sett,
    ls: true,
  },
  {
    name: "Shaco",
    position: "Jungle",
    imageUrl: Shaco,
    fh: true,
  },
  {
    name: "Shen",
    position: "Top",
    imageUrl: Shen,
    ls: true,
  },
  {
    name: "Sion",
    position: "Top",
    imageUrl: Sion,
    ls: true,
  },
  {
    name: "Sivir",
    position: "Bot",
    imageUrl: Sivir,
    ls: true,
  },
  {
    name: "Smolder",
    position: "Bot",
    imageUrl: Smolder,
    fh: true,
    warning:
      "The FH version is a testing version. Please join the FH Discord server for more information.",
  },
  {
    name: "Sona",
    position: "Support",
    imageUrl: Sona,
    ls: true,
  },
  {
    name: "Soraka",
    position: "Support",
    imageUrl: Soraka,
    ls: true,
  },
  {
    name: "Swain",
    position: "Support",
    imageUrl: Swain,
    ls: true,
  },
  {
    name: "Sylas",
    position: "Mid",
    imageUrl: Sylas,
    ls: true,
  },
  {
    name: "Syndra",
    position: "Mid",
    imageUrl: Syndra,
    ls: true,
    fh: true,
  },
  {
    name: "Tahm Kench",
    position: "Top, Support",
    imageUrl: TahmKench,
    warning:
      "The FH version is a testing version. Please join the FH Discord server for more information",
    fh: true,
  },
  {
    name: "Taliyah",
    position: "Jungle",
    imageUrl: Taliyah,
    ls: true,
  },
  {
    name: "Talon",
    position: "Mid,Jungle",
    imageUrl: Talon,
    ls: true,
    /*    rs: true,*/
  },
  {
    name: "Taric",
    position: "Support",
    imageUrl: Taric,
    ls: true,
  },
  {
    name: "Teemo",
    position: "Top,Support",
    imageUrl: Teemo,
    ls: true,
  },
  {
    name: "Thresh",
    position: "Support",
    imageUrl: Thresh,
    ls: true,
  },
  {
    name: "Tristana",
    position: "Bot",
    imageUrl: Tristana,
    ls: true,
  },
  {
    name: "Trundle",
    position: "Top,Jungle",
    imageUrl: Trundle,
    fh: true,
  },
  {
    name: "Tryndamere",
    position: "Bot",
    imageUrl: Tryndamere,
    ls: true,
  },
  {
    name: "Twisted Fate",
    position: "Mid",
    imageUrl: TwistedFate,
    ls: true,
  },
  {
    name: "Twitch",
    position: "Bot",
    imageUrl: Twitch,
    ls: true,
  },
  /*  {
    name: "Udyr",
    position: "Jungle",
    imageUrl: Udyr,
    warning: "RS version supported only.",
    rs: true,
  },*/
  {
    name: "Urgot",
    position: "Top",
    imageUrl: Urgot,
    ls: true,
  },
  {
    name: "Varus",
    position: "Mid,Bot",
    imageUrl: Varus,
    ls: true,
  },
  {
    name: "Vayne",
    position: "Bot",
    imageUrl: Vayne,
    ls: true,
  },
  {
    name: "Veigar",
    position: "Mid",
    imageUrl: Veigar,
    fh: true,
  },
  {
    name: "Velkoz",
    position: "Mid",
    imageUrl: Velkoz,
    ls: true,
  },
  {
    name: "Vex",
    position: "Mid",
    imageUrl: Vex,
    fh: true,
  },
  {
    name: "Vi",
    position: "Jungle",
    imageUrl: Vi,
    fh: true,
  },
  {
    name: "Viego",
    position: "Jungle",
    imageUrl: Viego,
    ls: true,
  },
  {
    name: "Viktor",
    position: "Mid",
    imageUrl: Viktor,
    ls: true,
  },
  {
    name: "Vladimir",
    position: "Mid",
    imageUrl: Vladimir,
    fh: true,
  },
  {
    name: "Volibear",
    position: "Top,Jungle",
    imageUrl: Volibear,
    fh: true,
  },
  {
    name: "Warwick",
    position: "Top,Jungle",
    imageUrl: Warwick,
    ls: true,
  },
  {
    name: "Xayah",
    position: "Bot",
    imageUrl: Xayah,
    ls: true,
  },
  {
    name: "Xerath",
    position: "Mid",
    imageUrl: Xerath,
    fh: true,
  },
  {
    name: "XinZhao",
    position: "Jungle",
    imageUrl: XinZhao,
    ls: true,
  },
  {
    name: "Yasuo",
    position: "Mid,Bot",
    imageUrl: Yasuo,
    ls: true,
    /*  rs: true,*/
  },
  {
    name: "Yone",
    position: "Top,Mid",
    imageUrl: Yone,
    ls: true,
    /*  rs: true,*/
  },
  {
    name: "Yorick",
    position: "Top",
    imageUrl: Yorick,
    ls: true,
  },
  {
    name: "Yuumi",
    position: "Support",
    imageUrl: Yuumi,
    fh: true,
  },
  {
    name: "Zed",
    position: "Mid",
    imageUrl: Zed,
    ls: true,
  },
  {
    name: "Zeri",
    position: "Bot",
    imageUrl: Zeri,
    fh: true,
  },
  {
    name: "Ziggs",
    position: "Mid",
    imageUrl: Ziggs,
    ls: true,
  },
  {
    name: "Zilean",
    position: "Support",
    imageUrl: Zilean,
    ls: true,
  },
  {
    name: "Zoe",
    position: "Mid",
    imageUrl: Zoe,
    fh: true,
  },
  {
    name: "Zyra",
    position: "Support",
    imageUrl: Zyra,
    fh: true,
  },
];
