import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { champions } from "../data/Champions";
import { useChampionData } from "./ChampionDataContext";

const ChampionSearchContext = createContext(null);

export const useChampionSearch = () => useContext(ChampionSearchContext);

export const ChampionSearchProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const { championTotalLikes } = useChampionData();

  const filteredChampions = useMemo(() => {
    const lowerCaseSearchTerm = searchTerm
      ? searchTerm.toLowerCase().trim()
      : "";
    const lowerCaseSelectedPosition = selectedPosition
      ? selectedPosition.toLowerCase().trim()
      : "";
    let filtered = champions.filter((champion) => {
      const positions = champion.position
        .toLowerCase()
        .split(",")
        .map((pos) => pos.trim());
      let searchTermMatch;

      if (lowerCaseSearchTerm) {
        switch (lowerCaseSearchTerm) {
          case "ls":
            searchTermMatch = champion.ls;
            break;
          case "fh":
            searchTermMatch = champion.fh;
            break;
          case "rs":
            searchTermMatch = champion.rs;
            break;
          default:
            searchTermMatch = champion.name
              .toLowerCase()
              .includes(lowerCaseSearchTerm);
            break;
        }
      } else {
        searchTermMatch = true;
      }

      let positionMatch =
        !lowerCaseSelectedPosition ||
        positions.includes(lowerCaseSelectedPosition);

      return searchTermMatch && positionMatch;
    });
    filtered.sort((a, b) => {
      const likesA = championTotalLikes[a.name] || 0; // Default to 0 if no likes
      const likesB = championTotalLikes[b.name] || 0; // Default to 0 if no likes

      if (sortOrder === "asc") {
        return likesA - likesB;
      } else if (sortOrder === "desc") {
        return likesB - likesA;
      } else {
        return 0; // No sorting
      }
    });

    return filtered;
  }, [championTotalLikes, searchTerm, selectedPosition, sortOrder]);

  const handlePositionChange = useCallback((position) => {
    setSelectedPosition((prev) => (prev === position ? "" : position));
    setSearchTerm("");
  }, []);

  const handleScoreSort = useCallback((order) => {
    setSortOrder((prev) => (prev === order ? "" : order));
  }, []);

  const contextValue = {
    searchTerm,
    setSearchTerm,
    selectedPosition,
    sortOrder,
    filteredChampions,
    handlePositionChange,
    handleScoreSort,
  };

  return (
    <ChampionSearchContext.Provider value={contextValue}>
      {children}
    </ChampionSearchContext.Provider>
  );
};
