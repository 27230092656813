import React from "react";
import { useUser } from "../../contexts/UserContext";
import styled from "styled-components";
import { Favorite } from "@mui/icons-material";

const LikesIconWrapper = styled.div`
  position: relative;
  color: #e0485c;
  display: flex;
  justify-content: center;
`;

const LikesText = styled.span`
  position: absolute;
  font-size: 1rem; // Adjust the size of the text here
  font-weight: bold;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledFavoriteIcon = styled(Favorite)`
  color: #fbfbfb;
  font-size: 2.5rem !important;
`;
const RemainingLikes = () => {
  const { user } = useUser();

  if (!user || user.likesRemaining === undefined) return null;

  return (
    <LikesIconWrapper>
      <StyledFavoriteIcon />
      <LikesText>{user.likesRemaining}</LikesText>
    </LikesIconWrapper>
  );
};

export default RemainingLikes;
