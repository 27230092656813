import styled from "styled-components";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

export const StyledArrowUpward = styled(ArrowUpward)`
  &.MuiSvgIcon-root {
    color: white;
    font-size: 35px;
    opacity: ${({ $active, $noneSelected }) =>
      $noneSelected ? 1 : $active ? 1 : 0.5};
  }

  &.MuiSvgIcon-root:hover {
    opacity: 1 !important;
  }
`;

export const StyledArrowDownward = styled(ArrowDownward)`
  &.MuiSvgIcon-root {
    color: white;
    font-size: 35px;
    opacity: ${({ $active, $noneSelected }) =>
      $noneSelected ? 1 : $active ? 1 : 0.5};
  }

  &.MuiSvgIcon-root:hover {
    opacity: 1 !important;
  }
`;
